import React from 'react';
import './App.css';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

const targetDate = new Date('2024-10-16T14:25:00'); //2024-10-16T14:25:00

const App = () => {

    return (
        <div className="App">
            <FlipClockCountdown to={targetDate}>
                <img src={"smiley_heart.png"} alt="Smiley heart" />
            </FlipClockCountdown>
        </div>
    );
}
export default App;
